import { Link, useLocation, useNavigate  } from 'react-router-dom';
import React, { Fragment, useState } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import insightLogo from '../../util/Images/InsightsSolutionsLogo.png';
import { BiTrash, BiShare} from 'react-icons/bi';
import {AiOutlineEdit} from 'react-icons/ai';
import { VscCopy } from 'react-icons/vsc';
import { MdOutlineLensBlur } from 'react-icons/md';
import './header.css';
import CustomPopup from '../common/customPopup/CustomPopup';
import MyLinkDataForm from '../../pages/explore/MyLinkDataForm';
import {postRequest, putRequest } from '../../services/axiosClient';
import { getBaseUrl } from '../../util/getBaseUrl';
import LoadingAnimation from '../common/loadingAnimation/LoadingAnimation';
import { removeDefaults, updateMylinkName, filterByMylinkId, getErrorMsg } from '../../util/functions';
import InfoMessage from '../common/InfoMessage';
import  useDashboardStore  from '../../store/dashboard/useDashboardStore';
import { backendURL } from '../../config/constants';
import ReactMenu from '../common/ReactMenu';
import {  MenuGroup, MenuItem,  SubMenu } from '@szhsin/react-menu';
import { RiSurveyLine } from 'react-icons/ri';

const urlSaveMyLinkId =`${getBaseUrl()}${backendURL().getMyLinkId}`;
const urlDeleteUserView = getBaseUrl() + '/api/datasrcs/1/schemas/dataviz/tables/mylink_user/records/';
const urlUploadMyLinkId =`${getBaseUrl()}${backendURL().uploadMyLinkId}`;




const Header = props => {
    const { heading, userInfo,  isAdmin } = props;
    // getting states from dashboard store
    // set.. ones are functions and others are states
    const { linksMenu, dashboardMenu, setLinksMenu, newView, setNewView} = useDashboardStore();
    const [formDataName,setFormDataName] =useState('');
    // react router dom location and navigate 
    const location = useLocation();
    const navigate = useNavigate();
    const [popupView, setPopupView] = useState({status:false});
    
    // Keep only one state for popup
    const [popup, setPopup] = useState({ status: false, action: '', title: '', loading: false, content:true,  });

    // Handler for opening a popup
    const openPopup = (action, view, title) => {
        setPopup({ 
            status: true, 
            action, 
            title, 
            loading: false, 
            content:true,
            view
        });
    };

    // Handler for closing a popup
    const closePopup = () => {
        setPopup({ status: false, action: '', title: '', loading: false, content:true,});
        setFormDataName('');
    };

    // Generic handler for popup actions
    // This function handles popup actions
    const handlePopupAction = async () => {

        // Update the state of the popup to indicate loading and no content
        setPopup(prevState => ({ ...prevState, loading: true, content:false }));

        try {
            // variable for storing success message
            let successMessage; 

            // switch case to handle different popup actions
            switch (popup.action) {
            case 'delete':
            // If the action is 'delete', 
            // send a put request to delete user view
                await putRequest(urlDeleteUserView + popup.view.my_link_user_id, { active_flag: false });
            
                // After successful deletion, update the links menu
                setLinksMenu(filterByMylinkId(linksMenu, popup.view.my_link_user_id));
            
                // Set the success message for deletion
                successMessage = 'Deleted successfully!';
                break;
            
            case 'rename':
            // If the action is 'rename', send a put request to change the name
                await putRequest(urlSaveMyLinkId + `/${popup.view.mylink_id}`, { name: formDataName });
            
                // After successful rename, update the links menu
                setLinksMenu(updateMylinkName(linksMenu, popup.view.mylink_id, formDataName));
            
                // Set the success message for rename
                successMessage = 'Name changed successfully!';
                break;
            
            case 'default':{
            // If the action is 'default', modify the links
            // menu and then send a post request
                const {obj, removedRecords} = removeDefaults(linksMenu, popup.view.mylink_id, popup.view.product_name, popup.view.workbook_view_name);
                await postRequest(urlUploadMyLinkId, removedRecords);
            
                // After successful update, set the new links menu
                setLinksMenu(obj);
            
                // Set the success message for updating default view
                successMessage = 'Updated default view successfully!';
                break;
            }
            // If the action does not match any
            //  of the cases above, throw an error
            default:
                throw new Error('Unsupported action');
            }

            // Update the popup state to indicate no 
            // loading and provide a success message
            setPopup(prevState => ({ ...prevState, loading: false, message: successMessage, infoStatus: true, isError:false }));
        } catch (err) {
        // If an error occurs, capture the error message 
        // and update the popup state accordingly
            const msg = getErrorMsg(err);
            setPopup(prevState => ({ ...prevState, loading: false, message: 'An error occurred:' + msg, infoStatus: true, isError:true, }));
        }
    };

    // This function closes the popup by
    // resetting the popup view state
    const handleClosePopup = () => {
        setPopupView({status:false, formInfo:{}});
    };
    
    // This function opens the popup and sets the formInfo, 
    // formTitle, tableId, productId, workbookId,
    // and workbookViewId in the popup view state
    const handleClick =(mylink, action) =>{
        setPopupView({
            status:true, 
            formInfo:{action:action, myLinkId:mylink.mylink_id, formName:action === 'edit' ? mylink.mydata_view_name : mylink.mydata_view_name + '(copy)'}, 
            formTitle:mylink.product_name, 
            tableId:mylink.table_id, 
            productId: mylink.product_id, 
            workbookId:mylink.workbook_id,
            workbookViewId:mylink.workbook_view_id
        });
    };
    
    // Default email, subject and body 
    // for sharing the dashboard link
    const email = '';
    const subject = 'Sharing Insights And Solutions Dashboard Link';
    const body = 'I think you would be interested in viewing this dashboard: ' + window.location.origin;
   
    // The actions available for each mylink
    const mylinkActions = outerMenuInfo => (
        <SubMenu label={<span className='header-dot-menu' data-testid='seperator-menu'></span>}>
            <MenuItem className='mylink-menu-actions'  onClick={()=> handleClick(outerMenuInfo, 'edit')}><AiOutlineEdit/><span>Edit</span></MenuItem>
            <MenuItem  className='mylink-menu-actions'  onClick={()=> handleClick(outerMenuInfo, 'copy')}><VscCopy/><span>Duplicate</span></MenuItem>
            <MenuItem className='mylink-menu-actions'  onClick={()=> openPopup('delete', outerMenuInfo, 'Are you sure you want to delete this mylink?')}><BiTrash/><span>Delete</span></MenuItem>
            <MenuItem className='mylink-menu-actions'  onClick={()=> openPopup('rename', outerMenuInfo, 'Enter your new mylink name')}><AiOutlineEdit/><span>Rename</span></MenuItem>
            <MenuItem className='mylink-menu-actions'  ><BiShare/><a href={`mailto:${email}?subject=${subject}&body=${body + outerMenuInfo.link}`}><span>Share</span></a></MenuItem>
            {!outerMenuInfo.default_flag ? 
                <MenuItem className='mylink-menu-actions' onClick={()=> openPopup('default', outerMenuInfo, 'Are you sure you want to set this mylink as default?')}><MdOutlineLensBlur/><span>Set Default</span></MenuItem>: 
                <MenuItem className='mylink-menu-actions' onClick={()=> openPopup('default', outerMenuInfo, 'Are you sure you want to remove default mylink?')}><MdOutlineLensBlur/><span>Remove Default</span></MenuItem>
            }
        </SubMenu>
    );

    const dashboardMenuData = ()=> dashboardMenu.map(item => {
        return (
            <MenuItem
                key={item.product_group} value={item.product_group}
            >
                <Link to={'dashboards/' + item.dashboard_path}>{item.product_group}</Link>
            </MenuItem>
        );
    });
    
    // Extract the keys from the linksMenu 
    // object and assign them to mylinks
    const mylinks = Object.keys(linksMenu);


    const mylinkMenu = (
        <MenuGroup takeOverflow>
            <div onMouseEnter={() => newView ? setNewView(false) : null}>
                {mylinks.map(product => {
                    const workbookViews = Object.keys(linksMenu[product]);
                    return (
                        <div key={`div-${product}`} className='mylink-container'>
                            <span className='mylink-menu-title'>{product}</span>
                            {workbookViews.map(view => {
                                return (
                                    <div key={`div-${view}`} className='mylink-sub-container'>
                                        <span className='mylink-sub-menu-title'>{view}</span>
                                        {linksMenu[product][view].map(menu => {
                                            return (
                                                <div
                                                    className='mylink-menu-item'
                                                    key={menu.mylink_id}
                                                >
                                                    <span 
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            navigate(menu.link);
                                                        }}
                                                        className='mylink-item-name'
                                                    >{menu.mydata_view_name}
                                                        {menu.default_flag && <span className='mylink-default'>default</span>}
                                                    </span>
                                                
                                                    {mylinkActions(menu)}
                                                </div>
                                            );
                                        })}
                       
                                    </div>
                                );
                            })}
                            <div className='separator-menu'></div>
                        </div>
                    );
                })}
            </div>
        </MenuGroup>
    );

    return <div
        className='header'
    >
        <div style={{ display: 'inline' }}>
            <Link to={'/home'}>
                <img
                    height='58px'
                    alt="Insights & Solutions Technology Services"
                    src={insightLogo}
                ></img>
            </Link>
        </div>
        <div>
            <h2 className='font-display-bold'>{heading}</h2>
        </div>
        {popupView.status &&
            <CustomPopup formType='other' tableName={`My Links Form: ${popupView.formTitle}`} onClose={handleClosePopup} minWidth='80vw' backColor={'#fafafa'} minHeight='500px'>
                <MyLinkDataForm 
                    selectedTableId={popupView.tableId} 
                    userId={userInfo.wwid} 
                    getUserViews={()=>{}} 
                    formInfo={popupView.formInfo} 
                    setIsEditClicked={setPopupView} 
                    formType={'myLink'} 
                    workbookViewId={popupView.workbookViewId} 
                    productId={popupView.productId}
                    workbookId={popupView.workbookId}
                />
            </CustomPopup>
        }
        {popup.status && 
                <CustomPopup formType='other' tableName={popup.title} onClose={closePopup}>
                    <div className='data-link-popup'>
                        {((popup.action==='delete') && popup.content) && <div className='data-link-btns'>
                            <button data-testid='cancel-btn' className='btn btn-trans' onClick={closePopup} style={{color:'blue'}}>Cancel</button>
                            <button data-testid='delete-btn' className='btn btn-red' onClick={handlePopupAction}>Delete</button>
                        </div>}
                        {popup.action==='rename' && <input
                            className="data-link-rename"
                            label="New My Link Name"
                            onChange={e => setFormDataName(e.target.value)}
                            value={formDataName}
                            placeholder="Enter new name"
                            data-testid='data-link-name-input'
                        />}
                        {((popup.action!=='delete') && popup.content) && <div className='data-link-btns'>
                            <button data-testid='save-btn' className='btn btn-green' onClick={handlePopupAction} style={{paddingLeft:'10px', paddingRight:'10px'}}>Save</button>
                            <button data-testid='cancel-btn' className='btn btn-trans' onClick={closePopup} style={{color:'blue'}}>Cancel</button>
                        </div>}
                        { popup.loading ? <LoadingAnimation/> : 
                            <div>
                                <InfoMessage
                                    message={popup.message}
                                    isError={popup.isError}
                                    onClose={null}
                                    status={popup.infoStatus}
                                    timer={false}
                                    close={true}
                                />
                            </div>
                        }
                    </div> 
                </CustomPopup>
        }
        <div className='header-nav'>
            {!location.pathname.includes('/error') && (
                <Fragment>                
                    {location.pathname.includes('/dashboards') && 
                        <>
                            <ReactMenu renderMenu={dashboardMenuData} label='Dashboards'/>
                            <div style={{position:'relative'}}>
                                <ReactMenu renderMenu={mylinkMenu} label='My Links'/>
                            </div>
                        </>
                    }  
                    {location.pathname.includes('/dashboards') && <div className='header-menu-btn'>
                        <Link className='font-display-med' to='/explore' >My Data</Link>
                    </div> }
                    {isAdmin && <span className="icon is-medium" style={{ marginRight: '15px' }}>
                        <Tooltip title='Administration Page'>
                            <Link to={{pathname: '/admin'}} className={'topbar-link'} data-testid='cards'>
                                <IconButton className='primaryColor'>
                                    <SupervisorAccountIcon/>
                                </IconButton> 
                            </Link> 
                        </Tooltip>
                    </span>}
                    <span className="icon is-medium" style={{ marginRight: '10px' }}>
                            
                        <Tooltip title='Confluence page'>
                            <IconButton className='primaryColor' onClick={() => window.open('https://confluence.jnj.com/display/AFFQ')}>
                                <HelpCenterIcon sx={{fontSize:'26px'}}/>
                            </IconButton>  
                        </Tooltip>
                    </span>
                    <span className="icon is-medium" style={{ marginRight: '10px' }}>
                        <Tooltip title='Contact Us'>
                            <IconButton className='primaryColor' onClick={() => window.open('https://web.yammer.com/main/org/its.jnj.com/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIxNDI2NDc2NyJ9/all')}>
                                <ContactMailIcon/>
                            </IconButton>  
                        </Tooltip>
                    </span>
                    <span className="icon is-medium" style={{ marginRight: '10px'}}>
                        <Tooltip title='Survey'>
                            <IconButton className='primaryColor'>
                                <Link to='/survey' style={{width:24, height:24}}><RiSurveyLine size={24}/></Link>
                            </IconButton>
                        </Tooltip>
                    </span>
                    <span className="icon is-medium"  style={{ marginRight: '10px', marginLeft: '5px' }}>
                        <span className="primaryColor" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            { userInfo && userInfo.profilePhoto
                                ? <img style={{ borderRadius: '50%', height: '32px'}} alt="Insights & Solutions Technology Services" src={`data:image/png;base64,${userInfo.profilePhoto}`}/>
                                : <PersonIcon/> }
                        </span>
                    </span>
                    
                    <span className='font-display-med' style={{height:'40px',display: 'flex', alignItems: 'center'}}>{userInfo.userName}</span>
                </Fragment>
            )}
        </div>
    </div>;
};

Header.propTypes = {
    heading:PropTypes.string,
    userInfo:PropTypes.object,
    isAdmin:PropTypes.bool,
};

export default Header;
