import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import Loading from '../../../components/common/loading';
import { DataTable } from '@jauj/reactpkg-tsis-datatable';

import LoadingAnimation from '../../../components/common/loadingAnimation/LoadingAnimation';
import { BiEdit, BiTrash, BiCopy } from 'react-icons/bi';
import { Tooltip, IconButton } from '@mui/material';


const  getType = (config, filter) => {
    // data type
    const baseType = config?.ref_display_value ? 'string' : config?.data_type;

    // map filter types
    const numericTypes = ['number', 'int', 'bigint', 'numeric', 'integer'];
    const dateTypes = ['date', 'timestamp without time zone'];

    if (config?.data_type === 'ARRAY') { 
        return filter === 'data' ? 'string' : 'stringFilter';
    }

    // for numbers
    if (numericTypes.includes(baseType)) {
        return filter === 'data' ? baseType : 'numericFilter';
    }

    // for dates
    if (dateTypes.includes(baseType)) {
        return filter === 'data' ? 'date' : 'dateFilter';
    }

    // default return is string and string function
    return 'stringFilter';
};

function Datagrid({state, handleEditClick, handleDeleteButtonClick}) {
    const [columns, setColumns] = useState([]);
    useEffect(()=>{
        const actions = {
            
            header: 'Actions',
            disableFilter:true,
            minWidth:120,
            cell: cellValues => {
                return (<>    {state.tableUniqueId ? <>
                    <Tooltip title='Edit'>
                        <IconButton className='hover-pointer edit-icon' role='infoedit' onClick={event => handleEditClick(event, cellValues.row.original, 'edit')}>
                            <BiEdit size='20px' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Copy'>
                        <IconButton tooltip='Copy' className='hover-pointer copy-icon' role='infocopy' onClick={event => handleEditClick(event, cellValues.row.original, 'copy')} data-hover='Copy'>
                            <BiCopy size='20px' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Delete'>
                        <IconButton tooltip='Delete' className='hover-pointer delete-icon' role='infodelete' onClick={() => handleDeleteButtonClick(cellValues.row.original, cellValues.row.original[state.tableUniqueId])} data-hover='Delete'>
                            <BiTrash size='20px' />
                        </IconButton>
                    </Tooltip>
                </> : <td> <LoadingAnimation width={'30px'} height={'30px'} type={true} role='loadingAnimation' /></td>}
                </>)
                ;
            }
        };

        const cols = [actions];
        const getCharLength = (len)=>{
            return len > 999 ? 300 : '';
        };
        state.Columns.forEach(col=> {
            cols.push({
                accessorKey: col, 
                header: state.Config[col]?.column_name_display ?? col,
                textAlign:'left',
                minWidth: getCharLength(state.Config[col]?.character_maximum_length || 1),
                dataType: getType(state.Config[col] || {}, 'data'),
                filterFn: getType(state.Config[col] || {}, 'filter')
            });
        });
        setColumns(cols);
    },[]);
    return (
        <div style={{ height: 300, width: '100%' }}>
            {state.tableUniqueId ? <> 
                <br></br>
                <DataTable
                    {...{
                        tableData:state.dvRows,
                        columnConfig:columns, 
                        disableDownload:false, 
                        defaultFileName:'table', 
                        showFooter:false,
                        showRecordNumber:true,
                    }}
                />
            </>: <Loading isLoading={true} />}
        </div>
    );
}

Datagrid.propTypes = {
    state: PropTypes.shape({
        Columns: PropTypes.any,
        Config: PropTypes.any,
        dvRows: PropTypes.any,
        tableUniqueId: PropTypes.any
    }),
    handleDeleteButtonClick: PropTypes.any,
    handleEditClick: PropTypes.any
};

export default Datagrid;