import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { getRequest } from '../../services/axiosClient';
import LoadingAnimation from '../common/loadingAnimation/LoadingAnimation';
import { getBaseUrl } from '../../util/getBaseUrl';
import { backendURL } from '../../config/constants';
import './FileManager.css';
import FileSidebar from './FileSidebar';
import FileList from './FileList';
import FileBreadcrumbs from './FileBreadcrumbs';

const FileManager = ()=> {

    const [fileData, setFileData] = useState(null);
    const [folders, setFolders] = useState([]);
    const [currentPath, setCurrentPath] = useState(['log']); 
    const [expandedPaths, setExpandedPaths] = useState(new Set(['log'])); 
    const [fileCount, setFileCount] = useState(0);
    const [isViewing, setViewing] = useState(false);
    const [currentLog, setCurrentLog] = useState({});

    const [searchParams] = useSearchParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getRequest(`${getBaseUrl()}${backendURL().logMappingURL}`);
                setFileData(res.data);
                setFolders(buildFolderStructure(res.data)); 
            } catch (err) {
                toast.error(`Error while retrieving file list. ${err}`);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (searchParams.get('path')) { 
            let p = [];
            p = searchParams.get('path').split('/');
            setCurrentPath(['log', ...p]);
        } else { 
            setCurrentPath(['log']);
        }
    }, [searchParams]);


    const buildFolderStructure = (data, path = []) => {
        return data.map(item => {
            if (item.isDir) {
                return {
                    name: item.name,
                    path: [...path, item.name],
                    children: item.children ? buildFolderStructure(item.children, [...path, item.name]) : []
                };
            }
            return null;
        }).filter(item => item !== null);
    };


    const handlePathChange = path => {
        const newPath = path.filter(i => i !== 'log').join('/');
        const currentUrl = window.location.origin + window.location.pathname + (newPath.length > 0 ? `?path=${newPath}` : '');
        window.history.pushState({}, '', currentUrl);

        setCurrentPath(path);
        setViewing(false);
    };

    const handleView = async item => {
        setViewing(true);
        try {
            let viewUrl = `${getBaseUrl()}${backendURL().logDownloadURL}/${item.path}/${item.name}/?isView=true`;
            const response = await getRequest(viewUrl);
            const res = response.data;
            setCurrentLog(res);
        } catch (err) {
            toast.error(`Error getting file: ${item.name}. ${err}`);
        }
    };

    const handleDownload = async item => {
        const toastId = toast.loading('Downloading...');
        try {
            let downloadUrl = '';
            if(item.isDir){
                downloadUrl = getBaseUrl() +  '/api/logfolder' + `/${item.path}/${item.name}/`;
            } else {
                downloadUrl = `${getBaseUrl()}${backendURL().logDownloadURL}/${item.path}/${item.name}/`;
            }
            const response = await getRequest(downloadUrl, {
                responseType: 'blob', 
            });
            const responseBlob = response.data;
            const link = document.createElement('a');
            link.href = URL.createObjectURL(responseBlob);
            link.setAttribute('download', item.isDir ? `${item.name}.zip` : item.name); 
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            toast.update(toastId, { render: 'Downloaded ' + (item.isDir ? `${item.name}.zip` : item.name), type: 'success', isLoading: false, autoClose:6000, closeButton:true});
        } catch (err) {
            const fileName = item.isDir ? `${item.name}.zip` : item.name;
            toast.error(`Error downloading ${fileName}. ${err}`);
        }
    };

    const toggleFolder = path => {
        const pathString = path.join('/');
        if (expandedPaths.has(pathString)) {
            expandedPaths.delete(pathString);
        } else {
            expandedPaths.add(pathString);
        }
        setExpandedPaths(new Set(expandedPaths));
    };

    return (
        <div>
            <ToastContainer theme='light' position='bottom-right' />
            
            {fileData ? (
                <>
                    <FileBreadcrumbs currentPath={currentPath} handlePathChange={handlePathChange}  />
                    <div  className="file-manager-container">
                        <FileSidebar 
                            folders={folders}
                            handlePathChange={handlePathChange}
                            toggleFolder={toggleFolder}
                            expandedPaths={expandedPaths}
                        />
                        <FileList 
                            fileData={fileData} 
                            currentPath={currentPath} 
                            handlePathChange={handlePathChange} 
                            handleDownload={handleDownload}
                            setFileCount={setFileCount}
                            isViewing={isViewing}
                            setViewing={setViewing}
                            handleView={handleView}
                            currentLog={currentLog}
                        />
                    
                    </div>
                    <div className='file-list-footer'>
                        <span>
                            {currentPath.map((part, index) => (
                                <span key={index} >
                                    {part}{index < currentPath.length - 1 ? ' / ' : ''}
                                </span>
                            ))}
                        </span>
                
                        <span>{fileCount > 0 && `${fileCount} items`}</span>

                    </div></>
            ) : (
                <LoadingAnimation />
            )}
            
        </div>
    );
};

export default FileManager;
