/* eslint-disable react/prop-types */
import React from 'react';
import Select from 'react-select';
//using react hook form for intake of data.
import {Controller} from 'react-hook-form';
import CustomAsyncSelect from './CustomAsyncSelect';


//basic select input component for use in datagrid form
const SelectInput = props => {

    // eslint-disable-next-line react/prop-types
    const {data, control, actions, defaultValue, index } = props;

    //getting option label
    const optionLabel = option => props.hideId ? `${option.label}` : `${option.value} - ${option.label}`;
    
    return (
        <Controller
            name={data.column_name}
            control={control}
            rules={{ required: !!data.is_required }}
            defaultValue={defaultValue?.value}
            render={({ field, value}) => (
                <td key={index}>
                    <label htmlFor={data.column_name}>{data.is_required ? <span style={{fontWeight: 'bold'}}>{data.column_name_display}*</span> : data.column_name_display}:</label>
                    <br></br>
                    <div data-testid="my-select-component">
                        <Select
                            {...field}
                            options={actions}
                            name={data.column_name}
                            defaultValue={defaultValue}
                            value={actions.find(c => c.value === value)}
                            onChange={val => field.onChange(val.value)}
                            required={!!data.is_required}
                            menuPlacement="auto"
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            getOptionLabel={option => optionLabel(option)}
                        />
                    </div>
                </td>
            )}
        />);
};


// Function to change the key name in a single object
function changeKeyInObject(obj, oldKey, newKey) {
    if (oldKey in obj) {
        obj[newKey] = obj[oldKey];
        delete obj[oldKey];
    }
}
// Function to change the key name for all objects in the dictionary
const changeKeyInDictionary = (dictionary, oldKey, newKey) => {
    for (const key in dictionary) {
        if (dictionary[key] !== null) {
            changeKeyInObject(dictionary[key], oldKey, newKey);
        }
    }
};
//select component that works asyc with api call. 
//Utilizes custom async component
const AsyncSelectInput = props => {
 
    //default select index is used to identify item in larger form data.
    const {data, defaultSelectIndex, control, index, urlreq, formData, isMulti} = props;
    let defValue, defaultKeyNames;

    if(isMulti){
        defaultKeyNames = {label:data.multi_select_lkup_column_dv, value:data.multi_select_lkup_column};
        
        //original Key value pairs from data
        let formDataOg = formData[data.column_name];

        changeKeyInDictionary(formDataOg, data.multi_select_column, data.multi_select_lkup_column);
        changeKeyInDictionary(formDataOg, 'display_value', data.multi_select_lkup_column_dv);

        defValue = formDataOg;
       

    }else 
    {
        defaultKeyNames = {label:data.ref_display_value, value:data.foreign_column_name};
        defValue = [{[data.ref_display_value]:formData?.[data.column_name]?.[1] == null ? '' : formData?.[data.column_name]?.[1], [data.foreign_column_name]:defaultSelectIndex == null ? '' : defaultSelectIndex}];
    }

    return (<Controller
        name={data.column_name}
        control={control}
        rules={{ required: !!data.is_required }}
        defaultValue={defaultSelectIndex == null ? '' : defaultSelectIndex}
        render={field => (
            <td key={index} >
                <label htmlFor={data.column_name}>{data.is_required? <span style={{fontWeight: 'bold'}}>{data.column_name_display}*</span> : data.column_name_display}:</label>
                <br></br>
                <div data-testid="my-select-async-component">
                    <CustomAsyncSelect
                        name={data.column_name}
                        field={field}
                        urlReq={urlreq}
                        defValue={defValue}
                        defaultKeyNames={defaultKeyNames}
                        isMulti={isMulti}
                        isRequired={data.is_required == 1 ? true : false}
                    >
                    </CustomAsyncSelect>
                </div>
            </td>
        )}
    />);
};


//export of modules
export { SelectInput, AsyncSelectInput };

