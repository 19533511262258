import React, { useEffect, useState } from 'react';
import { DataTableEdit } from '@jauj/reactpkg-tsis-datatable-edit';
import { getRequest } from '../../../services/axiosClient';
import PropTypes from 'prop-types';
import LoadingAnimation from '../../../components/common/loadingAnimation/LoadingAnimation';
import { toast } from 'react-toastify';
import { getDetailErrorMsg } from '../../../util/functions';
import { transformBackendConfig } from '../../../util/tableFunctions';


const EditTable = ({config, url, setEditStatus, editStatus}) => {
    const [table, setTable] = useState({fetched:false, data:[], config:{}});
    const getTable =  async () =>{
        try {
            const res  = await getRequest(url + '?display-value=true');
            const { columnConfig, asyncFields} = transformBackendConfig(config);
            
            for (const item of res.data.data) {
                for (const key in asyncFields) {
                    if (item[key] !== null && item[key] !== undefined) {
                        const option = {
                            value: item[key],
                            label: item[`${key}_dv`]
                        };
                        asyncFields[key].push(option);
                    }
                }
            }

            setTable({fetched:true, data:res.data.data, config:columnConfig, asyncOptions:asyncFields});

        } catch (error) {
            toast.error(`Error with getting table data: ${getDetailErrorMsg(error)}`);
        }
    };

    useEffect(()=>{
        getTable();
    },[]);
    const handleUpdate = status =>{
        if(!editStatus){
            setEditStatus(status);
        }
    };
    return (
        <div>
            {
                table.fetched ? <DataTableEdit
                    {...{
                        tableData:table.data,
                        columnConfig:table.config, 
                        disableDownload:true, 
                        disableFilter:false,
                        themeColor:'#248232',
                        disableSearch:false,
                        saveUrl:url,
                        asyncDefaultOptions:table.asyncOptions,
                        onDataUpdate:handleUpdate,
                    }}
                /> : <LoadingAnimation/>
            }
        </div>
    );
};

EditTable.propTypes = {
    config: PropTypes.any,
    url:PropTypes.string,
    setEditStatus: PropTypes.func,
    editStatus:PropTypes.bool
};

export default EditTable;