import { getRequest } from '../services/axiosClient';
import { getBaseUrl } from './getBaseUrl';

export const createAsyncLoadOptions = (config) => {
    const {
        foreign_schema_name: schemaName,
        foreign_table_name: tableName,
        foreign_column_name: valueColumn,
        ref_display_value: labelColumn,
    } = config;
  
    const urlBase = `/api/datasrcs/1/schemas/${schemaName}/tables/${tableName}/records?`  +  (tableName === 'rmr_resource'  ? 'fields-all=display_name,username,wwid,employee_status&record-limit=10&search-fields=employee_status&search-term=active&search-fields=display_name,username,wwid&search-term=' : 'fields-all=id,name&record-limit=10&search-fields=id,name&search-term=');
    //asyncLoadOptions function
    return async (inputValue, callback) => {
        try {
            const url = getBaseUrl() + `${urlBase}${encodeURIComponent(inputValue)}`;
  
            const response = await getRequest(url);
  
            const data = response.data.data; 
  
            const options = data.map(item => ({
                value: item[valueColumn],
                label: item[labelColumn],
            }));
            callback(options);
        } catch (err) {
            console.error('Error fetching options:', err);
            callback([]);
        }
    };
};

export const mapDataType = backendDataType => {
    switch (backendDataType.toLowerCase()) {
    case 'character varying':
    case 'text':
        return 'string';
    case 'integer':
    case 'numeric':
    case 'bigint':
        return 'number';
    case 'boolean':
        return 'boolean';
    case 'date':
        return 'date';
    case 'timestamp without time zone':
        return 'datetime-local';
    case 'time without time zone':
        return 'time';
    default:
        return 'string'; 
    }
};

export const mapFilterType = backendDataType => {
    switch (backendDataType.toLowerCase()) {
    case 'character varying':
    case 'text':
        return 'stringFilter';
    case 'integer':
    case 'numeric':
    case 'bigint':
        return 'numericFilter';
    case 'date':
    case 'timestamp without time zone':
        return 'dateFilter';
    case 'boolean':
        return 'booleanFilter';
    default:
        return 'stringFilter'; 
    }
};
  

export const transformBackendConfig = backendConfig => {
    const columnConfig = [];
    const asyncFields = {};

    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(backendConfig)) {
        const isRequired = value.is_required == 1; 
        const column = {
            accessorKey: value.column_name,
            header: value.column_name_display || value.column_name,
            dataType: mapDataType(value.data_type),
            filterFn: mapFilterType(value.data_type),
            editable: (value.is_primary_key === 0),
            includeInSave: true,
            inputType:mapDataType(value.data_type),
            isRequired:isRequired
        };

        if(value.is_primary_key === 1){
            column.id = value.column_name;
            column.accessorFn = row => row[value.column_name];
        }
  
        if (value.async_lookup_flag) {
            column.asyncLoadOptions = createAsyncLoadOptions(value);
            column.inputType = 'async-select';
            column.hideId = false;
            column.isMulti = false; 
            column.placeholder = `Select ${column.header}`;
            asyncFields[value.column_name] = [];
        }

        if (value.display_values) {
            column.inputType = 'select';
            
            const options =  Object.keys(value.display_values).map(key => ({
                value: key,
                label: value.display_values[key],
            }));

            column.selectOptions = !isRequired ? [{value:null, label:'--Select--'}, ...options] : options;
            column.hideId = false;
            column.isMulti = false; 
            column.placeholder = `Select ${column.header}`;
        }

  
        columnConfig.push(column);
    }
  
    return {columnConfig, asyncFields};
};